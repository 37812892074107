import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { siteSettings } from '../../../actions/siteadmin/siteSettings';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import {
  Row,
  FormGroup,
  Col,
  FormControl,
  Form,
  Container,
  InputGroup
} from 'react-bootstrap';
import cx from 'classnames';
import LogoDropzone from './LogoDropzone';
import FaviconDropzone from './FaviconDropzone';
import s from './SiteSettingsForm.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages'
import submit from './submit'
import validate from './validate'
import { api, logoUploadDir, faviconUploadDir, siteUrl } from '../../../config'
import Loader from '../../Common/Loader';

export class SiteSettingsForm extends Component {

  static defaultProps = {
    loading: false,
    updateSiteSettings: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.space3}>
          <div>
            <label className={s.labelText} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderAddonFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
          <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
              <InputGroup.Text>{formatMessage(messages.minutes)}</InputGroup.Text>
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    );
  }


  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.space3}>
          <div>
            <label className={s.labelText} >{label}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={className}
              placeholder={label}
              as="textarea"
              rows='4'
            >
              {children}
            </FormControl>
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={s.space3}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
          {children}
        </Form.Control>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
            <InputGroup.Append>
              <InputGroup.Text>
                V
              </InputGroup.Text>
            </InputGroup.Append>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
          </InputGroup>
          {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, updateSiteSettings, appForceUpdate, favicon } = this.props;
    const { data: { getCurrencies } } = this.props;

    return (
      <div className={s.paddignLeftRight}>
        <Container fluid>
          <form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6} className={cx(s.marbtm14, s.spaceTop2)}>
                <div className={s.profileImgSection}>
                  <label>{formatMessage(messages.logo)}</label>
                  {logo && <div>
                    <img src={api.apiEndpoint + logoUploadDir + 'medium_' + logo} className={s.profileImg} />
                  </div>}
                  <div>
                    <LogoDropzone
                      className={cx(s.btnSecondary, s.profileNoPadding)}
                      subTextClass={s.subText}
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} className={cx(s.marbtm14, s.spaceTop2)}>
                <div className={s.profileImgSection}>
                  <label className={s.labelText} >{formatMessage(messages.favIconlogoLabel)}</label>
                  {favicon && <div>
                    <img src={siteUrl + faviconUploadDir + favicon} className={s.profileImg} />
                  </div>}
                  <div className={'commonFilepicker'}>
                    <FaviconDropzone
                      className={cx(s.btnSecondary, 'fileNoPadding')}
                      subTextClass={s.subText}
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                    />
                  </div>
                  <p className={s.pngFontSize}><FormattedMessage {...messages.pngOnlyLabel} /></p>

                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Field name="logoWidth" type="text" component={this.renderFormControl} label={formatMessage(messages.logoWidth)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="siteName" type="text" component={this.renderFormControl} label={formatMessage(messages.siteNameLabel)} maxlength={15} />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Field name="logoHeight" type="text" component={this.renderFormControl} label={formatMessage(messages.logoHeight)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="siteTitle" type="text" component={this.renderFormControl} label={formatMessage(messages.siteTitleLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="metaKeyword" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaKeywordLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="metaDescription" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaDescriptionLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="facebookLink" type="text" component={this.renderFormControl} label={formatMessage(messages.facebookURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="twitterLink" type="text" component={this.renderFormControl} label={formatMessage(messages.twitterURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="instagramLink" type="text" component={this.renderFormControl} label={formatMessage(messages.instagramURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="youtubeLink" type="text" component={this.renderFormControl} label={formatMessage(messages.youtubeURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactNumber" type="text" component={this.renderFormControl} label={formatMessage(messages.contactNumber)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.contactEmail)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactSkype" type="text" component={this.renderFormControl} label={formatMessage(messages.contactSkype)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="notificationInterval" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.notificationInterval)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field
                  name="currency"
                  placeholder={formatMessage(messages.currency)}
                  component={this.renderSelectField}
                  label={formatMessage(messages.currency)}
                  labelClass={s.labelText}
                  fieldClass={cx(s.formControlSelect, s.formControlInput)}
                >
                  <option value={""}>{formatMessage(messages.currency)}</option>
                  {
                    getCurrencies && getCurrencies.map((item, index) => {
                      return item.isEnable == 1 && <option key={index} value={item.symbol}>{item.symbol}</option>
                    })
                  }
                </Field>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field
                  name="distanceUnits"
                  placeholder={formatMessage(messages.distanceUnits)}
                  component={this.renderSelectField}
                  label={formatMessage(messages.distanceUnits)}
                  labelClass={s.labelText}
                  fieldClass={cx(s.formControlSelect, s.formControlInput)}
                >
                  <option value="km">{formatMessage(messages.kmLabel)}</option>
                  <option value="miles">{formatMessage(messages.milesLabel)}</option>
                </Field>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={s.labelText} >{formatMessage(messages.forceUpdate)}</label><br />
                    <Field name="appForceUpdate" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.forceUpdate)}
                      <option value="true">{formatMessage(messages.enable)}</option>
                      <option value="false">{formatMessage(messages.disable)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={6} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={s.labelText} >{formatMessage(messages.preferredDelivery)}</label><br />
                    <Field name="preferredDelivery" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.preferredDelivery)}
                      <option value="1">{formatMessage(messages.meetAtDoor)}</option>
                      <option value="2">{formatMessage(messages.leaveAtDoor)}</option>
                      <option value="3">{formatMessage(messages.pickUpOutside)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {appForceUpdate === 'true' && <Row>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="eaterAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.eaterAndriodVersion)}
                      maxlength={10} />
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="eaterIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.eateriosVersion)} maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="driverAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driverAndriodVersion)}
                      maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="driverIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driveriosVersion)} maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
            </Row>}

            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                <FormGroup className={s.space3}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.submitButton)}
                      show={updateSiteSettings || submitting}
                      buttonType={'submit'}
                      className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                      disabled={updateSiteSettings || submitting}
                      isSuffix={true}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    )
  }
}

const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props
  await refetch()
  dispatch(siteSettings())
}

SiteSettingsForm = reduxForm({
  form: 'SiteSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(SiteSettingsForm);

const selector = formValueSelector('SiteSettingsForm')
const mapState = (state) => ({
  logo: selector(state, 'homeLogo'),
  appForceUpdate: selector(state, 'appForceUpdate'),
  updateSiteSettings: state.siteSettings.updateSiteSettings,
  favicon: selector(state, 'favicon'),
})

const mapDispatch = {
  siteSettings
}

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(gql`query {
      getCurrencies {
        id
        symbol
        isEnable
      }
    }`, {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
  )(SiteSettingsForm));