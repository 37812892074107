import React, { Component } from 'react'
import s from './ProducerForm.css';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import Loader from '../../../Common/Loader';
import updateProducerMutation from '../updateProducer.graphql';
import { flowRight as compose } from 'lodash';
import { toastr } from 'react-redux-toastr';
import history from '../../../../history';
import { graphql } from 'react-apollo';

export class ProducerForm extends Component {
    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <Form.Group className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <Form.Control
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </Form.Control>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </Form.Group>
            </div>
        );
    }

    handleSubmit = async (values) => {
        try {
            const { mutate } = this.props;
            const { data } = await mutate({ variables: { id: values.id, producerName: values.producerName, producerStates: values.producerStates, isActive: values.isActive == 'true' } });
            if (data?.updateProducer?.status == 200) {
                if (!values.id) toastr.success('Success!', 'The producer has been added successfully.');
                else toastr.success('Success!', 'The producer has been updated successfully.');
                history.push('/siteadmin/producer');
            } else {
                toastr.error('Error!', data?.updateProducer?.errorMessage || "Something went wrong. Please try again.");
            }
        } catch (error) {
            toastr.error('Error!', error.message);
        }
    }

    render() {
        const { handleSubmit, loading, submitting, id } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(this.handleSubmit)} >

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div>
                                                <Field
                                                    name="producerName"
                                                    type="text"
                                                    placeholder={formatMessage(messages.producerName)}
                                                    component={this.renderField}
                                                    label={formatMessage(messages.producerName)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div>
                                                <Field
                                                    name="producerStates"
                                                    type="text"
                                                    placeholder={formatMessage(messages.producerStates)}
                                                    component={this.renderField}
                                                    label={formatMessage(messages.producerStates)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div>
                                                <label>{formatMessage(messages.status)}</label><br />
                                                <Field name="isActive" className={cx(s.formControlSelect, s.formControlInput, 'adminDropDown', 'adminDropDownRTL')} component="select">
                                                    <option value={true}>{formatMessage(messages.active)}</option>
                                                    <option value={false}>{formatMessage(messages.inactive)}</option>
                                                </Field>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={cx(s.displayInlineBlock, 'adminBtnSectionSpaceBottom')}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages[isNaN(id) ? "submitButton" : "update"])}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, 'adminBtnTextBrk', 'rtlBtnLoader')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/producer"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL', 'adminBtnSectionSpaceBottom', 'adminBtnTextBrk')} >{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

ProducerForm = reduxForm({
    form: 'ProducerForm',
    validate
})(ProducerForm);


const mapState = (state) => ({
})

const mapDispatch = {
}

export default compose(
    injectIntl,
    graphql(updateProducerMutation),
    withStyles(s),
    connect(mapState, mapDispatch)
)(ProducerForm);