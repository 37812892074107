import { editCategory } from '../../../../actions/siteadmin/Category/editCategory';

async function submit(values, dispatch) {
    
    await dispatch(
        editCategory(
            values.id,
            values.categoryName
        )
    )
}

export default submit;