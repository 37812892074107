import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Producer.css';
import getProducersQuery from './getProducers.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import ProducerList from '../../../components/SiteAdmin/Producer/ProducerList/ProducerList';

class Producer extends React.Component {

  render() {
    const { getProducers: { loading }, getProducers } = this.props;
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              <FormattedMessage {...messages.producerMenu} />
            </div>
            <div className={s.paddingRoutesSection}>
              {!loading && <ProducerList getProducers={getProducers} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getProducersQuery, {
    name: 'getProducers',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Producer);
