import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS
} from '../../constants/index';

export default function updateSiteSettings(siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink, logoHeight, logoWidth, metaKeyword, homeLogo, youtubeLink, currency, distanceUnits, appForceUpdate, eaterAndroidVersion, eaterIosVersion, driverAndroidVersion, driverIosVersion, preferredDelivery, favicon, contactNumber,contactEmail,contactSkype, notificationInterval) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: UPDATE_SITE_SETTINGS_START
        })

        try {
            const mutation = gql`
            mutation updateSiteSettings(
                $siteName: String,
                $siteTitle: String,
                $metaDescription: String, 
                $facebookLink: String,
                $twitterLink: String,
                $instagramLink: String,
                $logoHeight: Int,
                $logoWidth: Int,
                $metaKeyword: String,
                $homeLogo: String,
                $youtubeLink: String,
                $currency: String,
                $distanceUnits: String,
                $appForceUpdate: String,
                $eaterAndroidVersion: String,
                $eaterIosVersion: String,
                $driverAndroidVersion: String,
                $driverIosVersion: String,
                $preferredDelivery: String,
                $favicon: String
                $contactNumber: String,
                $contactEmail: String,
                $contactSkype: String,
                $notificationInterval: String,
                ) {
                    updateSiteSettings(
                    siteName: $siteName
                    siteTitle: $siteTitle
                    metaDescription: $metaDescription
                    facebookLink: $facebookLink
                    twitterLink: $twitterLink
                    instagramLink: $instagramLink
                    logoHeight: $logoHeight
                    logoWidth: $logoWidth
                    metaKeyword: $metaKeyword
                    homeLogo: $homeLogo
                    youtubeLink: $youtubeLink
                    currency: $currency
                    distanceUnits: $distanceUnits
                    appForceUpdate: $appForceUpdate
                    eaterAndroidVersion: $eaterAndroidVersion
                    eaterIosVersion: $eaterIosVersion
                    driverAndroidVersion: $driverAndroidVersion
                    driverIosVersion: $driverIosVersion
                    preferredDelivery: $preferredDelivery
                    favicon: $favicon
                    contactNumber: $contactNumber
                    contactEmail: $contactEmail
                    contactSkype:  $contactSkype
                    notificationInterval: $notificationInterval
                    ){
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    logoHeight,
                    logoWidth,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    distanceUnits,
                    appForceUpdate,
                    eaterAndroidVersion,
                    eaterIosVersion,
                    driverAndroidVersion,
                    driverIosVersion,
                    preferredDelivery,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval
                }
            })

            if (data && data.updateSiteSettings && data.updateSiteSettings.status == 200) {

                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                })
                // await dispatch(siteSettings())
                toastr.success('Success', 'Site settings changes have been successfully applied')
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                })
                toastr.error('Oops!', 'Something went wrong')
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            })
            toastr.error('Oops!', 'Something went wrong')
        }

    }
}