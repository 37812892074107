import gql from 'graphql-tag';
import histroy from '../../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';

export function addCategory(categoryName) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
    mutation addCategory(
        $categoryName: String
    ) {
        addCategory(
            categoryName: $categoryName,
        ) {
            status
            errorMessage
        }
    }`;

    dispatch(setLoaderStart('AddCategory'));
    
    const { data } = await client.mutate({
      mutation,
      variables: {
        categoryName
      }
    });
    
    dispatch(setLoaderComplete('AddCategory'));

    if (data && data.addCategory && data.addCategory.status === 200) {
      toastr.success('Success!', 'The category has been added successfully.');
      histroy.push('/siteadmin/category');
    } else if (data && data.addCategory && data.addCategory.status !== 200){
      toastr.error('Error!', data.addCategory.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}