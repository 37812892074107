import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Product.css';
import getProductsQuery from './getProducts.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import ProductList from '../../../components/SiteAdmin/Product/ProductList/ProductList';

class Product extends React.Component {

  render() {
    const { getProducts: { loading }, getProducts } = this.props;
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              <FormattedMessage {...messages.productMenu} />
            </div>
            <div className={s.paddingRoutesSection}>
              {!loading && <ProductList getProducts={getProducts} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getProductsQuery, {
    name: 'getProducts',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Product);
