import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import s from './StrainList.css';
import withStyles from 'isomorphic-style-loader/withStyles';

class StrainDescriptionModal extends Component {
    render() {
        const { show, content, onHide, modalHeading } = this.props;
        return (
            <Modal show={show} onHide={onHide} className={'adminModalStyle'}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={s.modalWordBreak}>
                    <div className={s.modalContent}>{content}</div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withStyles(s)(StrainDescriptionModal);
