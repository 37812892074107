import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import getOrderDetailsQuery from '../../../routes/shop/orderDetails/getOrders.graphql';

export default function updateFoodStatus(id, isOrderDetailPage) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];
            let mutation = gql`
                mutation updateFoodStatus($id: Int!) {
                    updateFoodStatus (id: $id) {
                        status
                        errorMessage
                    }
                }
            `;

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries
            });

            if(data && data.updateFoodStatus && data.updateFoodStatus.status === 200 ){
                toastr.success('Success', 'We will find the nearest delivery partner to pick up the order!');
            } else {
                toastr.error('Error', data && data.updateFoodStatus && data.updateFoodStatus.errorMessage );
            }

            return true;
        } catch(error) {
            toastr.error('Error', error);
            
        }
    }
};