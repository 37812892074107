import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.categoryName) {
        errors.categoryName = messages.required;
    } else if (values.categoryName && values.categoryName.trim().toString() == "") {
        errors.categoryName = messages.required;
    } else if(values.categoryName.length > 100) {
        errors.categoryName = messages.exceedLimit100
    }

    // if (!values.description) {
    //     errors.description = messages.required;
    // } else if (values.description && values.description.trim().toString() == "") {
    //     errors.description = messages.required;
    // }

    return errors;
};

export default validate