exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Open Sans', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #3e0056;\n  --btn-boxShadow-bg: #3e0056;\n  --btn-bordercolor-bg: #3e0056;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #3e0056;\n  --btn-secondary-bg: #3e0056;\n  --title-color: #1a1b1c;\n  --content-color: #3a3a3a;\n  --green-color: #9EC15E;\n  --grey-color: #3C3838;\n\n}\n._1LQ5- {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._1s7Xi {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n._1sfRe {\n  font-size: 44px;\n  font-weight: 600;\n}\n.v9395 {\n  font-size: 30px;\n  margin-bottom: 24px;\n}\n._3Gsto {\n  margin-right: 10px;\n}\n@media screen and (max-width: 991px) {\n  .I88BA {\n    width: 100%;\n  }\n  ._3Gsto {\n    margin-right: 0px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1LQ5-",
	"container": "_1s7Xi",
	"textJumbo": "_1sfRe",
	"subText": "v9395",
	"firstBtn": "_3Gsto",
	"bntWidth": "I88BA"
};