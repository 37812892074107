import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import cx from 'classnames';

// style
import s from './HeaderAfterLogin.css';

// components
import Switch from '../../../Common/Switch';
import DropdownNavLink from '../../../Common/DropdownNavLink';
import Link from '../../../Link';
import HeaderModal from '../../../HeaderModal/HeaderModal';

// redux actions
import { shopLogout } from '../../../../actions/shop/logout/shopLogout';
import shopUserActivityChange from '../../../../actions/shop/userAccount/shopUserActivityChange';
import { openHeaderModal } from '../../../../actions/siteadmin/modalActions';

// Image
import chef from './chef.svg';
import languageIcon from '../../../../../public/Icons/Language-black.svg';
import whiteLanguageIcon from '../../../../../public/Icons/Language-white.svg';

// helper
import { formatLocale } from '../../../../helpers/formatLocale';

// Local
import messages from '../../../../locale/messages';

// Config
import { api, restaurantImageUploadDir } from '../../../../config';
export class HeaderAfterLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dropDown: false,
        };
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUnmount() {
        const { socket, userId } = this.props;
        if (socket && userId) {
            socket.removeListener(`webUserLogout-${userId}`);
        }
    }

    async handleSwitch(status) {
        const { shopUserActivityChange, shopStatus } = this.props;
        shopUserActivityChange(status);
    }

    handleChange(e) {
        const { openHeaderModal } = this.props;
        openHeaderModal('languageModal');
    }


    render() {
        const { shopLogout, shopPicture, shopStatus, currentLocale } = this.props;
        const { socket, userId } = this.props;
        if (socket && userId) {
            socket.on(`webUserLogout-${userId}`, (data) => {
                if (typeof window !== "undefined") window.location.assign('/shopUserLogout');
            });
        }
        const isBrowser = typeof window !== 'undefined';
        const smallDevice = isBrowser ? window.matchMedia('(max-width: 767px)').matches : undefined;
        return (
            <div className={cx(s.root)}>

                <Nav className={cx("mr-auto")}>
                    <HeaderModal />

                    <div className={s.menuHeight}>
                        <Switch
                            switchOnLabel={<FormattedMessage {...messages.available} />}
                            switchOffLabel={<FormattedMessage {...messages.unavailable} />}
                            handleSwitch={this.handleSwitch}
                            value={shopStatus}
                            component={'HeaderAfterLogin'}
                        />
                    </div>
                    <div className={s.menuHeight}>
                        <a
                            onClick={(e) => this.handleChange(e)}
                            className={cx(s.cursurPointer)}>
                            <span className={s.languageIcon}>
                                {smallDevice ? <img src={languageIcon + '?' + Math.random()} /> : <img src={whiteLanguageIcon + '?' + Math.random()} />}
                            </span>
                            {/* <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextlanguageRTL')}>
                                {formatLocale(currentLocale)}
                            </span> */}
                        </a>
                    </div>
                    <div className={cx(s.menuHeight, 'headerDroup')}>
                        <form action={'/shopLogout'} method="post">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    {shopPicture ?
                                        <img
                                            src={api.apiEndpoint + restaurantImageUploadDir + shopPicture}
                                            height={30}
                                            width={30}
                                            alt={'Profile Image'}
                                            className={cx(s.imgSection, 'imgSectionRTL')}
                                            onClick={() => this.setState({ dropDown: true })}
                                        >
                                        </img>
                                        :
                                        <img
                                            src={chef}
                                            height={30}
                                            width={30}
                                            alt={'Profile Image'}
                                            className={cx(s.imgSection, 'imgSectionRTL')}
                                            onClick={() => this.setState({ dropDown: true })}
                                        >
                                        </img>
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={s.linkBorderColor}>
                                    <DropdownNavLink to={'/restaurant/edit-profile'}>
                                        <FormattedMessage {...messages.editProfile} />
                                    </DropdownNavLink>
                                    <DropdownNavLink to={'/restaurant/document'}>
                                        <FormattedMessage {...messages.documentUpload} />
                                    </DropdownNavLink>
                                    <DropdownNavLink to={'/restaurant/password'}>
                                        <FormattedMessage {...messages.password} />
                                    </DropdownNavLink>
                                    <Dropdown.Item as="button" type="submit" className={s.menuHeight}>
                                        <FormattedMessage {...messages.logout} />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </form>
                    </div>
                </Nav>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    shopPicture: state && state.account && state.account.data && state.account.data.profile && state.account.data.profile.picture,
    shopStatus: state && state.account && state.account.data && state.account.data.isActive,
    userId: state.account && state.account.data && state.account.data.id,
    currentLocale: state.intl.locale
});

const mapDispatchToProps = {
    shopLogout,
    shopUserActivityChange,
    openHeaderModal
};

export default withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(HeaderAfterLogin));