import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import EditProducer from './EditProducer';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
    let url, type;
    if (params.type == 'add') {
        url = "/siteadmin/producer/add";
        type = 'add';
    } else if (params.type == 'edit') {
        type = 'edit';
        url = "/siteadmin/producer/edit";
    }
    const title = intl.formatMessage(messages[type == "edit" ? "editProducer" : "addProducerHeading"]);

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }

    if (type == 'edit' && !params.id) return;
    const id = Number(params.id);

    // Admin restriction
    if (!restrictUrls(url, adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    return {
        title,
        component: (
            <AdminLayout>
                <EditProducer title={title} id={id} />
            </AdminLayout>
        )
    }
}

export default action;