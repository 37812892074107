import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.producerName || values.producerName.trim().toString() == "") {
        errors.producerName = messages.required;
    } else if (values.producerName.toString().length > 255) {
        errors.producerName = messages.exceedLimit;
    }
    if (!values.producerStates || values.producerStates.trim().toString() == "") {
        errors.producerStates = messages.required;
    } else if (values.producerStates.toString().length > 255) {
        errors.producerStates = messages.exceedLimit;
    }

    return errors;
};

export default validate