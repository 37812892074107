import { addCategory } from '../../../../actions/siteadmin/Category/addCategory';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
    // if (!values.categoryImage) {
    //     toastr.error('Error!', "Cuisine image is required.");
    //     return;
    // }

    await dispatch(
        addCategory(
            values.categoryName
        )
    )
}

export default submit;