import React, { Component } from 'react';
import ProducerForm from '../../../components/SiteAdmin/Producer/ProducerForm'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getStrain from './getStrain.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditStrain.css';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import StrainForm from '../../../components/SiteAdmin/Strain/StrainForm/StrainForm';
import messages from '../../../locale/messages';


export class EditStrain extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getStrain }, id } = this.props;

        let initialValues = {
            isActive: 'true',
            strainType: 'Indica'
        };

        if (!loading && getStrain?.result) {
            initialValues = {
                id: getStrain?.result?.id,
                strainName: getStrain?.result?.strainName,
                strainType: getStrain?.result?.strainType,
                rating: getStrain?.result?.rating,
                numberOfRating: getStrain?.result?.numberOfRating,
                effects: getStrain?.result?.effects,
                negEffects: getStrain?.result?.negEffects,
                terpines: getStrain?.result?.terpines,
                flavor: getStrain?.result?.flavor,
                description: getStrain?.result?.description,
                shortDescription: getStrain?.result?.shortDescription,
                aka: getStrain?.result?.aka,
                genetics: getStrain?.result?.genetics,
                isActive: getStrain?.result?.isActive.toString()
            }
        }
        if (loading && id) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages[id ? 'editStrain' : 'addStrainHeading']} />
                        </div>
                        <div>
                            <StrainForm initialValues={initialValues} id={id}/>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(withStyles(s), graphql(getStrain, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: true
    }),
    skip: props => Boolean(!props.id)
}))(EditStrain)
