import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import s from './ProducerList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';
import history from '../../../../history';
import { Button, FormControl } from 'react-bootstrap';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';

import { flowRight as compose } from 'lodash';
import updateProducerMutation from '../updateProducer.graphql';
import deleteProducerMutation from '../deleteProducer.graphql';
import { graphql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';

class ProducerList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSearchClick(searchList) {
    const { getProducers: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleDelete(id) {
    const { deleteProducer, getProducers: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteProducer({ variables: { id } });

    if (response?.data?.deleteProducer?.status === 200) {
      toastr.success('Success', `The producer has been deleted.`);
      this.setState({ currentPage: 1 });
      refetch(variables);
    } else {
      toastr.error('Error!', response?.data?.deleteProducer?.errorMessage || "Something went wrong. Please try again.");
    }
    this.setState({
      isDisabled: 0
    })
  }

  paginationData(currentPage) {
    const { getProducers: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, item, currentPage) {
    const { updateProducer, getProducers: { refetch } } = this.props;
    let status = e.target.value == 'true';
    let variables = { id: item.id, producerStates: item.producerStates, producerName: item.producerName, isActive: status };

    const response = await updateProducer({ variables });

    if (response?.data?.updateProducer?.status === 200) {
      toastr.success('Success!', 'The producer has been updated successfully.');
      this.setState({ currentPage });
      refetch();
    } else {
      toastr.error('Error!', response?.data?.updateProducer?.errorMessage || "Something went wrong. Please try again.");
    }
  }

  render() {
    const { getProducers } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={cx(s.searchInput, 'adminSearchInputSpace')}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection, 'textAlignLeftRTL', 'mobileTextLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={"/siteadmin/producer/add"} className={s.addlinkBtn}>{formatMessage(messages.addProducer)}</Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'newAdminListTable', { ['newAdminListNoDataTable']: (!getProducers?.getProducers?.results?.length) })}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.producerName} /></th>
                <th scope="col"><FormattedMessage {...messages.producerStates} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
              </tr>
            </thead>
            <tbody>
              {
                getProducers?.getProducers?.results?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.producerName)}>{item.producerName}</td>
                      <td data-label={formatMessage(messages.producerStates)}>{item.producerStates}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>

                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/producer/edit/' + item.id} className={cx('editAlignIcon', 'adminTableIconSection')}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL', 'adminTableIconSpaceNone')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'adminTableIconSection')} disabled={isDisabled}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'adminTableIconSpaceNone', 'trashIconRTL', 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                (!getProducers?.getProducers?.results?.length) && (
                  <tr>
                    <td colspan="12" className={cx(s.noRecords, 'mobTextCenter')}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          getProducers?.getProducers?.results?.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={getProducers?.getProducers?.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={100}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.producers)}
            />
          </div>
        }
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(updateProducerMutation, {
    name: 'updateProducer'
  }),
  graphql(deleteProducerMutation, {
    name: 'deleteProducer'
  })
)(ProducerList);