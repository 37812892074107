import React, { Component } from 'react';
import s from './EditCategoryForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import ImageDropzone from './ImageDropzone';
import { api, categoryUploadDir } from '../../../../config';
import Loader from '../../../Common/Loader';

export class EditCategoryForm extends Component {

    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <Form.Group className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <Form.Control
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </Form.Control>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </Form.Group>
            </div>
        );
    }

    render() {
        const { handleSubmit, currency, id, categoryMarkerImage, categoryImage, loading, submitting } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    {/* <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div className={cx(s.centerFlex, s.profileImgSection)}>
                                                <label>{formatMessage(messages.categoryIcon)}</label>
                                                {
                                                    categoryImage && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + categoryUploadDir}medium_${categoryImage})` }} />
                                                }
                                                <div>
                                                    <ImageDropzone
                                                        key={'categoryImage'}
                                                        className={cx(s.btnSecondary, s.profileNoPadding)}
                                                        subTextClass={s.subText}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        id={id}
                                                        oldFileName={categoryImage}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col> */}
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div>
                                                <Field
                                                    name="categoryName"
                                                    type="text"
                                                    placeholder={formatMessage(messages.categoryName)}
                                                    component={this.renderField}
                                                    label={formatMessage(messages.categoryName)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.space3}>
                                            <div>
                                                <label>{formatMessage(messages.status)}</label><br />
                                                <Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                    <option value={true}>{formatMessage(messages.active)}</option>
                                                    <option value={false}>{formatMessage(messages.inactive)}</option>
                                                </Field>
                                            </div>
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col lg={12} md={12} sm={12} xs={12}>
                                        <Field name="description" component={this.renderFormControlTextArea} label={formatMessage(messages.description)} />
                                    </Col> */}
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={s.displayInlineBlock}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages.update)}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/category"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

EditCategoryForm = reduxForm({
    form: 'EditCategoryForm',
    onSubmit: submit,
    validate
})(EditCategoryForm);

const selector = formValueSelector('EditCategoryForm');

const mapState = state => ({
    currency: state.currency,
    categoryImage: selector(state, 'categoryImage'),
    id: selector(state, 'id'),
    loading: state.loader.EditCategory
});

const mapDispatch = {};


export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(EditCategoryForm)));