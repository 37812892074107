import React, { Component } from 'react'
import s from './StrainForm.css';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import Loader from '../../../Common/Loader';
import updateStrainMutation from '../updateStrain.graphql';
import { flowRight as compose } from 'lodash';
import { toastr } from 'react-redux-toastr';
import history from '../../../../history';
import { graphql } from 'react-apollo';

export class StrainForm extends Component {
    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <Form.Group className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <Form.Control
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </Form.Control>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </Form.Group>
            </div>
        );
    }

    handleSubmit = async (values) => {

        try {
            const { mutate } = this.props;
            const { data } = await mutate({
                variables: {
                    id: values.id,
                    strainName: values.strainName,
                    strainType: values.strainType || null,
                    rating: values.rating,
                    numberOfRating: parseFloat(values.numberOfRating),
                    effects: values.effects,
                    negEffects: values.negEffects,
                    terpines: values.terpines,
                    flavor: values.flavor,
                    description: values.description,
                    shortDescription: values.shortDescription,
                    aka: values.aka,
                    genetics: values.genetics,
                    isActive: values.isActive == 'true'
                }
            });
            if (data?.updateStrain?.status == 200) {
                if (!values.id) toastr.success('Success!', 'The strain has been added successfully.');
                else toastr.success('Success!', 'The strain has been updated successfully.');
                history.push('/siteadmin/strain');
            } else {
                toastr.error('Error!', data?.updateStrain?.errorMessage || "Something went wrong. Please try again.");
            }
        } catch (error) {
            toastr.error('Error!', error.message);
        }
    }

    renderFieldComponent = ({ label, name, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Group className={s.space3}>
                    <div>
                        <Field
                            name={name}
                            type="text"
                            placeholder={formatMessage(messages[placeholder || label])}
                            component={this.renderField}
                            label={formatMessage(messages[label])}
                            labelClass={s.labelText}
                            fieldClass={s.formControlInput}
                        />
                    </div>
                </Form.Group>
            </Col>
        )
    }

    renderTextAreaField = ({ label, name, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Group className={s.space3}>
                    <div>
                        <Field
                            name={name}
                            placeholder={formatMessage(messages[placeholder || label])}
                            component={this.renderFormControlTextArea}
                            label={formatMessage(messages[label])}
                            labelClass={s.labelText}
                            fieldClass={s.formControlInput}
                        />
                    </div>
                </Form.Group>
            </Col>
        )
    }

    renderDropdown = ({ label, name, options }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Group className={s.space3}>
                    <div>
                        <label>{formatMessage(messages[label])}</label><br />
                        <Field name={name} className={cx(s.formControlSelect, s.formControlInput, 'adminDropDown', 'adminDropDownRTL')} component="select">
                            {options}
                        </Field>
                    </div>
                </Form.Group>
            </Col>
        )
    }

    fieldsArray = () => [
        { label: 'strainName', name: 'strainName' },
        {
            label: 'strainType', name: 'strainType', field: 'dropdown',
            options: [
                <option value={""}>{this.props.intl.formatMessage(messages.notApplicable)}</option>,
                <option value={"Indica"}>{this.props.intl.formatMessage(messages.indica)}</option>,
                <option value={"Sativa"}>{this.props.intl.formatMessage(messages.sativa)}</option>,
                <option value={"Hybrid"}>{this.props.intl.formatMessage(messages.hybrid)}</option>
            ]
        },
        { label: 'rating', name: 'rating' },
        { label: 'numberOfRating', name: 'numberOfRating' },
        { label: 'effects', name: 'effects' },
        { label: 'negEffects', name: 'negEffects' },
        { label: 'terpines', name: 'terpines' },
        { label: 'flavor', name: 'flavor' },
        { label: 'description', name: 'description', field: 'textarea' },
        { label: 'shortDescription', name: 'shortDescription', field: 'textarea' },
        { label: 'aka', name: 'aka' },
        { label: 'genetics', name: 'genetics' },
        {
            label: 'status', name: 'isActive', field: 'dropdown',
            options: [
                <option value={true}>{this.props.intl.formatMessage(messages.active)}</option>,
                <option value={false}>{this.props.intl.formatMessage(messages.inactive)}</option>
            ]
        },
    ]

    render() {
        const { handleSubmit, loading, submitting, id } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(this.handleSubmit)} >

                                    {
                                        this.fieldsArray().map(item => {
                                            if (item.field == 'dropdown') return this.renderDropdown({ label: item.label, name: item.name, options: item.options })
                                            else if (item.field == 'textarea') return this.renderTextAreaField({ label: item.label, name: item.name })
                                            return this.renderFieldComponent({ label: item.label, name: item.name })
                                        })
                                    }

                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={cx(s.displayInlineBlock, 'adminBtnSectionSpaceBottom')}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages[isNaN(id) ? "submitButton" : "update"])}
                                                    show={submitting || loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, 'adminBtnTextBrk', 'rtlBtnLoader')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/strain"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'adminBtnSectionSpaceBottom', 'adminBtnTextBrk', 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

StrainForm = reduxForm({
    form: 'StrainForm',
    validate
})(StrainForm);


const mapState = (state) => ({
})

const mapDispatch = {
}

export default compose(
    injectIntl,
    graphql(updateStrainMutation),
    withStyles(s),
    connect(mapState, mapDispatch)
)(StrainForm);