import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeBanner.css';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    Row, Container, Col
} from 'react-bootstrap';

//Images
import BannerImage from '../../../../public/SiteImages/homeBannermo.png';
import FoodPlate from '../../../../public/SiteImages/food-plate.png';
import FoodBG from '../../../../public/SiteImages/bg-green.svg';

//Locale 
import messages from '../../../locale/messages';
import { api } from '../../../config';

class RestaurantBanner extends React.Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this);
    }

    scrollTop() {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { data } = this.props;
        let homeSettings = {};

        data && data.homePageData.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            // <div className={cx(s.layout4Container)}>
            //     <div className={cx(s.bannerBackgroundImage)}
            //         style={{ backgroundImage: `url(${api.apiEndpoint + "/images/homepage/" + homeSettings.homeSectionImage1})` }} />
            //     <Container className={cx(s.bannerLayoutContainer)}>
            //         <Row>
            //             <div className={s.bannerDisplayGrid}>
            //                 <div className={s.searchFormContainer}>
            //                     <div className={s.searchFormWrap}>
            //                         <Col md={12} lg={12} sm={12} xs={12}>
            //                             <h1>
            //                                 {homeSettings.homeSectionTitle1}
            //                             </h1>
            //                             <p>
            //                                 {homeSettings.homeSectionDescription}
            //                             </p>
            //                         </Col>
            //                     </div>
            //                 </div>
            //                 <div className={s.formSection}>
            //                     <div className={s.platebgSection}>
            //                         <img src={FoodBG} className={s.platebg} />
            //                     </div>
            //                     <div className={cx(s.plateZoom, 'plateZoomRTL')}>
            //                         <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.homeSectionImage2} className={s.plateImage} />
            //                     </div>
            //                 </div>
            //             </div>
            //         </Row>
            //     </Container>
            // </div>
            <div className={s.bannerContainer}>
                <div className={s.homeBannerBg}>
                    <div className={s.homeBannerBorder}>
                        <div className={cx(s.bannerLayout)}>
                            <div className={s.bannerGrid}>
                                <div className={s.bannerTextSection}>
                                    <div>
                                        <h1 className={s.bannerTitle}>{homeSettings.homeSectionTitle1}</h1>
                                        <p className={s.bannerSubText}>{homeSettings.homeSectionDescription}</p>
                                        <p className={cx(s.bannerSubText, 'textCapitalize')}>**<FormattedMessage {...messages.comingSoon} />**</p>
                                    </div>
                                </div>
                                <div className={s.bannerImgSection}>
                                    <div className={cx(s.bannerImg, 'bannerImgAlignRTL')} style={{ backgroundImage: `url(${api.apiEndpoint + "/images/homepage/" + homeSettings.homeSectionImage2})` }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(RestaurantBanner)));