import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import s from './StrainList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';
import { Button, FormControl } from 'react-bootstrap';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';

import { flowRight as compose } from 'lodash';
import updateStrainMutation from '../updateStrain.graphql';
import deleteStrainMutation from '../deleteStrain.graphql';
import { graphql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';
import StrainDescriptionModal from './StrainDescriptionModal';

class StrainList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0,
      modalContent: null,
      modalHeading: null
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSearchClick(searchList) {
    const { getStrains: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleDelete(id) {
    const { deleteStrain, getStrains: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteStrain({ variables: { id } });

    if (response?.data?.deleteStrain?.status === 200) {
      toastr.success('Success', `The strain has been deleted.`);
      this.setState({ currentPage: 1 });
      refetch(variables);
    } else {
      toastr.error('Error!', response?.data?.deleteStrain?.errorMessage || "Something went wrong. Please try again.");
    }
    this.setState({
      isDisabled: 0
    })
  }

  paginationData(currentPage) {
    const { getStrains: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, item, currentPage) {
    const { updateStrain, getStrains: { refetch } } = this.props;
    let status = e.target.value == 'true';
    let variables = {
      id: item.id,
      strainName: item.strainName,
      strainType: item.strainType,
      rating: item.rating,
      numberOfRating: item.numberOfRating,
      effects: item.effects,
      negEffects: item.negEffects,
      terpines: item.terpines,
      flavor: item.flavor,
      description: item.description,
      shortDescription: item.shortDescription,
      aka: item.aka,
      genetics: item.genetics,
      isActive: status
    };

    const response = await updateStrain({ variables });

    if (response?.data?.updateStrain?.status === 200) {
      toastr.success('Success!', 'The strain has been updated successfully.');
      this.setState({ currentPage });
      refetch();
    } else {
      toastr.error('Error!', response?.data?.updateStrain?.errorMessage || "Something went wrong. Please try again.");
    }
  }

  onHide = () => {
    this.setState({ modalContent: null })
  }

  viewMore = (content, heading) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        {content?.substr(0, 20)}
        {content?.length > 20 && <span>{' '}<a className={cx(s.cursorPointer, s.viewMoreText)} onClick={() => this.setState({ modalContent: content, modalHeading: heading })}>{formatMessage(messages.viewMore)}</a></span>}
      </>
    )
  }

  render() {
    const { getStrains } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled, modalContent, modalHeading } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <StrainDescriptionModal modalHeading={modalHeading} show={Boolean(modalContent)} content={modalContent} onHide={this.onHide} />
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={cx(s.searchInput, 'adminSearchInputSpace')}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection, 'textAlignLeftRTL', 'mobileTextLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={"/siteadmin/strain/add"} className={s.addlinkBtn}>{formatMessage(messages.addStrain)}</Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'newAdminListTable', { ['newAdminListNoDataTable']: (!getStrains?.getStrains?.results?.length) })}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.strainName} /></th>
                <th scope="col"><FormattedMessage {...messages.strainType} /></th>
                <th scope="col"><FormattedMessage {...messages.rating} /></th>
                <th scope="col"><FormattedMessage {...messages.numberOfRating} /></th>
                <th scope="col"><FormattedMessage {...messages.effects} /></th>
                <th scope="col"><FormattedMessage {...messages.negEffects} /></th>
                <th scope="col"><FormattedMessage {...messages.terpines} /></th>
                <th scope="col"><FormattedMessage {...messages.flavor} /></th>
                <th scope="col"><FormattedMessage {...messages.description} /></th>
                <th scope="col"><FormattedMessage {...messages.shortDescription} /></th>
                <th scope="col"><FormattedMessage {...messages.aka} /></th>
                <th scope="col"><FormattedMessage {...messages.genetics} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
              </tr>
            </thead>
            <tbody>
              {
                getStrains?.getStrains?.results?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.strainName)}>{item.strainName}</td>
                      <td data-label={formatMessage(messages.strainType)}>{(item.strainType && ['Indica', 'Hybrid', 'Sativa'].includes(item.strainType)) ? formatMessage(messages[item.strainType?.toLowerCase()]) : formatMessage(messages.notApplicable)}</td>
                      <td data-label={formatMessage(messages.rating)}>{item.rating}</td>
                      <td data-label={formatMessage(messages.numberOfRating)}>{item.numberOfRating}</td>
                      <td data-label={formatMessage(messages.effects)}>{item.effects}</td>
                      <td data-label={formatMessage(messages.negEffects)}>{item.negEffects}</td>
                      <td data-label={formatMessage(messages.terpines)}>{item.terpines}</td>
                      <td data-label={formatMessage(messages.flavor)}>{item.flavor}</td>
                      <td className={cx(s.textAlignLeft, 'textAlignRightRTL')} data-label={formatMessage(messages.description)}>{this.viewMore(item.description, formatMessage(messages.description))}</td>
                      <td className={cx(s.textAlignLeft, 'textAlignRightRTL')} data-label={formatMessage(messages.shortDescription)}>{this.viewMore(item.shortDescription, formatMessage(messages.shortDescription))}</td>
                      <td data-label={formatMessage(messages.aka)}>{item.aka}</td>
                      <td data-label={formatMessage(messages.genetics)}>{item.genetics}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>

                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/strain/edit/' + item.id} className={cx('adminTableIconSection', 'editAlignIcon')}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'adminTableIconSpaceNone', 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'adminTableIconSection')} disabled={isDisabled}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'adminTableIconSpaceNone', 'trashIconRTL', 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                (!getStrains?.getStrains?.results?.length) && (
                  <tr>
                    <td colspan="17" className={cx(s.noRecords, 'mobTextCenter')}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          getStrains?.getStrains?.results?.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={getStrains?.getStrains?.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.strains)}
            />
          </div>
        }
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(updateStrainMutation, {
    name: 'updateStrain'
  }),
  graphql(deleteStrainMutation, {
    name: 'deleteStrain'
  })
)(StrainList);