import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.productName || values.productName.trim().toString() == "") {
        errors.productName = messages.required;
    } else if (values.productName.toString().length > 255) {
        errors.productName = messages.exceedLimit;
    }

    if (!values.thc || values.thc.trim().toString() == "") {
        errors.thc = messages.required;
    } else if (values.thc.toString().length > 255) {
        errors.thc = messages.exceedLimit;
    }

    if (!values.cbd || values.cbd.trim().toString() == "") {
        errors.cbd = messages.required;
    } else if (values.cbd.toString().length > 255) {
        errors.cbd = messages.exceedLimit;
    }

    if (!values.cbg || values.cbg.trim().toString() == "") {
        errors.cbg = messages.required;
    } else if (values.cbg.toString().length > 255) {
        errors.cbg = messages.exceedLimit;
    }

    if (!values.productRating || values.productRating.trim().toString() == "") {
        errors.productRating = messages.required;
    } else if (values.productRating.toString().length > 255) {
        errors.productRating = messages.exceedLimit;
    }

    if (!values.numberOfReviews && values.numberOfReviews !== 0) {
        errors.numberOfReviews = messages.required;
    } else if (isNaN(values.numberOfReviews)) {
        errors.numberOfReviews = messages.floatError;
    } else if (values.numberOfReviews.toString().length > 5) {
        errors.numberOfReviews = messages.exceedLimit;
    }



    return errors;
};

export default validate