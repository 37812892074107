import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import s from './ProductList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';
import { Button, FormControl } from 'react-bootstrap';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';

import { flowRight as compose } from 'lodash';
import updateProductMutation from '../updateProduct.graphql';
import deleteProductMutation from '../deleteProduct.graphql';
import { graphql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';
import { api, productUploadDir } from '../../../../config';

class ProductList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSearchClick(searchList) {
    const { getProducts: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleDelete(id) {
    const { deleteProduct, getProducts: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteProduct({ variables: { id } });

    if (response?.data?.deleteProduct?.status === 200) {
      toastr.success('Success', `The product has been deleted.`);
      this.setState({ currentPage: 1 });
      refetch(variables);
    } else {
      toastr.error('Error!', response?.data?.deleteProduct?.errorMessage || "Something went wrong. Please try again.");
    }
    this.setState({
      isDisabled: 0
    })
  }

  paginationData(currentPage) {
    const { getProducts: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, item, currentPage) {
    const { updateProduct, getProducts: { refetch } } = this.props;
    let status = e.target.value == 'true';
    let variables = {
      id: item.id,
      producer: item.producer,
      category: item.category,
      productName: item.productName,
      strain: item.strain,
      thc: item.thc,
      cbd: item.cbd,
      cbg: item.cbg,
      productRating: item.productRating,
      numberOfReviews: item.numberOfReviews,
      image: item.image,
      isActive: status
    };

    const response = await updateProduct({ variables });

    if (response?.data?.updateProduct?.status === 200) {
      toastr.success('Success!', 'The product has been updated successfully.');
      this.setState({ currentPage });
      refetch();
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }

  render() {
    const { getProducts } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={cx(s.searchInput, 'adminSearchInputSpace')}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection, 'textAlignLeftRTL', 'mobileTextLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={"/siteadmin/product/add"} className={s.addlinkBtn}>{formatMessage(messages.addProduct)}</Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'newAdminListTable', { ['newAdminListNoDataTable']: (!getProducts?.getProducts?.results?.length) })}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.producer} /></th>
                <th scope="col"><FormattedMessage {...messages.category} /></th>
                <th scope="col"><FormattedMessage {...messages.productName} /></th>
                <th scope="col"><FormattedMessage {...messages.productImage} /></th>
                <th scope="col"><FormattedMessage {...messages.strain} /></th>
                <th scope="col"><FormattedMessage {...messages.thc} /></th>
                <th scope="col"><FormattedMessage {...messages.cbd} /></th>
                <th scope="col"><FormattedMessage {...messages.cbg} /></th>
                <th scope="col"><FormattedMessage {...messages.productRating} /></th>
                <th scope="col"><FormattedMessage {...messages.numberOfReviews} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
              </tr>
            </thead>
            <tbody>
              {
                getProducts?.getProducts?.results?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.producer)}>{item.producerName}</td>
                      <td data-label={formatMessage(messages.category)}>{item.categoryName}</td>
                      <td data-label={formatMessage(messages.productName)}>{item.productName}</td>
                      <td data-label={formatMessage(messages.productImage)}><img src={`${api.apiEndpoint + productUploadDir}${'medium_' + item.image}`} /></td>
                      <td data-label={formatMessage(messages.strain)}>{item.strainName}</td>
                      <td data-label={formatMessage(messages.thc)}>{item.thc}</td>
                      <td data-label={formatMessage(messages.cbd)}>{item.cbd}</td>
                      <td data-label={formatMessage(messages.cbg)}>{item.cbg}</td>
                      <td data-label={formatMessage(messages.productRating)}>{item.productRating}</td>
                      <td data-label={formatMessage(messages.numberOfReviews)}>{item.numberOfReviews}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>

                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/product/edit/' + item.id} className={cx('editAlignIcon', 'adminTableIconSection')}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL', 'adminTableIconSpaceNone')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'adminTableIconSection')} disabled={isDisabled}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL', 'adminTableIconSpaceNone')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                (!getProducts?.getProducts?.results?.length) && (
                  <tr>
                    <td colspan="14" className={cx(s.noRecords, 'mobTextCenter')}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          getProducts?.getProducts?.results?.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={getProducts?.getProducts?.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.products)}
            />
          </div>
        }
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(updateProductMutation, {
    name: 'updateProduct'
  }),
  graphql(deleteProductMutation, {
    name: 'deleteProduct'
  })
)(ProductList);