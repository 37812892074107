exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Open Sans', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #3e0056;\n  --btn-boxShadow-bg: #3e0056;\n  --btn-bordercolor-bg: #3e0056;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #3e0056;\n  --btn-secondary-bg: #3e0056;\n  --title-color: #1a1b1c;\n  --content-color: #3a3a3a;\n  --green-color: #9EC15E;\n  --grey-color: #3C3838;\n\n}\n\n._2W1sz {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.CpU2p {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 380px;\n  \n}\n\n._1MhqW {\n    margin-bottom: 6px;\n    position: relative;\n  }\n\n.ucyzy {\n    overflow: hidden;\n  }\n\n.gVaNU {\n    position: relative;\n    z-index: 1;\n    display: block;\n    margin: 15px 0px;\n    width: 100%;\n    color: #767676;\n    text-align: center;\n    font-size: 14px;\n  }\n\n  ", ""]);

// exports
exports.locals = {
	"root": "_2W1sz",
	"container": "CpU2p",
	"formGroup": "_1MhqW",
	"formSection": "ucyzy",
	"horizontalLineThrough": "gVaNU"
};