import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.strainName || values.strainName.trim().toString() == "") {
        errors.strainName = messages.required;
    } else if (values.strainName.toString().length > 255) {
        errors.strainName = messages.exceedLimit;
    }
    // if (!values.strainType || values.strainType.trim().toString() == "") {
    //     errors.strainType = messages.required;
    // } else if (values.strainType.toString().length > 255) {
    //     errors.strainType = messages.exceedLimit;
    // }
    if (values.rating && values.rating.toString().length > 255) {
        errors.rating = messages.exceedLimit;
    }
    if (values.numberOfRating && isNaN(values.numberOfRating)) {
        errors.numberOfRating = messages.floatError;
    } else if (values.numberOfRating && values.numberOfRating.toString().length > 5) {
        errors.numberOfRating = messages.exceedLimit;
    }
    if (values.effects && values.effects.toString().length > 255) {
        errors.effects = messages.exceedLimit;
    }
    if (values.negEffects && values.negEffects.toString().length > 255) {
        errors.negEffects = messages.exceedLimit;
    }
    if (values.terpines && values.terpines.toString().length > 255) {
        errors.terpines = messages.exceedLimit;
    }
    if (values.flavor && values.flavor.toString().length > 255) {
        errors.flavor = messages.exceedLimit;
    }
    if (values.description && values.description.toString().length > 1500) {
        errors.description = messages.exceedLimit;
    }
    if (values.shortDescription && values.shortDescription.toString().length > 1500) {
        errors.shortDescription = messages.exceedLimit;
    }
    if (values.aks && values.aka.toString().length > 255) {
        errors.aka = messages.exceedLimit;
    }
    if (values.genetics && values.genetics.toString().length > 255) {
        errors.genetics = messages.exceedLimit;
    }

    return errors;
};

export default validate