import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Strain.css';
import getStrainsQuery from './getStrains.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import StrainList from '../../../components/SiteAdmin/Strain/StrainList/StrainList';

class Strain extends React.Component {

  render() {
    const { getStrains: { loading }, getStrains } = this.props;
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              <FormattedMessage {...messages.strainMenu} />
            </div>
            <div className={s.paddingRoutesSection}>
              {!loading && <StrainList getStrains={getStrains} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getStrainsQuery, {
    name: 'getStrains',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Strain);
