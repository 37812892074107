import React, { Component } from 'react'
import s from './ProductForm.css';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import Loader from '../../../Common/Loader';
import updateProductMutation from '../updateProduct.graphql';
import { flowRight as compose } from 'lodash';
import { toastr } from 'react-redux-toastr';
import history from '../../../../history';
import { graphql } from 'react-apollo';
import { api, productUploadDir } from '../../../../config';
import ImageDropzone from './ImageDropzone';

export class ProductForm extends Component {
    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <Form.Group className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <Form.Control
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </Form.Control>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </Form.Group>
            </div>
        );
    }

    handleSubmit = async (values) => {

        try {
            if (!values.image) {
                toastr.error('Error!', "Product image is required.");
                return;
            }
            const { mutate } = this.props;
            const { data } = await mutate({
                variables: {
                    id: values.id,
                    producer: values.producer,
                    category: values.category,
                    productName: values.productName,
                    strain: values.strain,
                    thc: values.thc,
                    cbd: values.cbd,
                    cbg: values.cbg,
                    productRating: values.productRating,
                    numberOfReviews: values.numberOfReviews,
                    image: values.image,
                    isActive: values.isActive == 'true'
                }
            });
            if (data?.updateProduct?.status == 200) {
                if (!values.id) toastr.success('Success!', 'The product has been added successfully.');
                else toastr.success('Success!', 'The product has been updated successfully.');
                history.push('/siteadmin/product');
            } else {
                toastr.error('Error!', data?.updateProduct?.errorMessage || "Something went wrong. Please try again.");
            }
        } catch (error) {
            toastr.error('Error!', error.message);
        }
    }

    renderFieldComponent = ({ label, name, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Group className={s.space3}>
                    <div>
                        <Field
                            name={name}
                            type="text"
                            placeholder={formatMessage(messages[placeholder || label])}
                            component={this.renderField}
                            label={formatMessage(messages[label])}
                            labelClass={s.labelText}
                            fieldClass={s.formControlInput}
                        />
                    </div>
                </Form.Group>
            </Col>
        )
    }

    renderDropdown = ({ label, name, options }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Group className={s.space3}>
                    <div>
                        <label>{formatMessage(messages[label])}</label><br />
                        <Field name={name} className={cx(s.formControlSelect, s.formControlInput, 'adminDropDown', 'adminDropDownRTL')} component="select">
                            {options}
                        </Field>
                    </div>
                </Form.Group>
            </Col>
        )
    }

    renderDropzone = ({ input, meta: { touched, error } }) => {
        const { formatMessage } = this.props.intl;
        const { id } = this.props;
        return (
            <>
                {
                    input.value && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + productUploadDir}${input.value})` }} />
                }
                <div>
                    <ImageDropzone
                        key={'categoryImage'}
                        className={cx(s.btnSecondary, s.profileNoPadding)}
                        subTextClass={s.subText}
                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                        defaultMessage={formatMessage(messages.chooseFile)}
                        change={input.onChange}
                        productId={id}
                        oldFileName={input.value}
                    />

                </div>
            </>

        )
    }

    renderUploaderField = ({ label, name }) => {
        const { formatMessage } = this.props.intl;

        return (
            <Col lg={12} md={12} sm={6} xs={12}>
                <Form.Group className={s.space3}>
                    <div className={cx(s.centerFlex, s.profileImgSection, 'mobTextCenter')}>
                        <label>{formatMessage(messages[label])}</label>
                        <Field
                            name={name}
                            component={this.renderDropzone}
                        />
                    </div>
                </Form.Group>
            </Col>
        )
    }

    fieldsArray = () => [
        {
            label: 'producer', name: 'producer', field: 'dropdown',
            options: this.props.getActiveProducers?.map(item => {
                return <option value={item.id}>{item.producerName}</option>
            })
        },
        {
            label: 'category', name: 'category', field: 'dropdown',
            options: this.props.getActiveCategories?.map(item => {
                return <option value={item.id}>{item.prod_cat}</option>
            })
        },
        { label: 'productName', name: 'productName' },
        {
            label: 'strain', name: 'strain', field: 'dropdown',
            options: this.props.getActiveStrains?.map(item => {
                return <option value={item.id}>{item.strainName}</option>
            })
        },
        { label: 'thc', name: 'thc' },
        { label: 'cbd', name: 'cbd' },
        { label: 'cbg', name: 'cbg' },
        { label: 'productRating', name: 'productRating' },
        { label: 'numberOfReviews', name: 'numberOfReviews' },
        { label: 'productImage', name: 'image', field: 'image' },
        {
            label: 'status', name: 'isActive', field: 'dropdown',
            options: [
                <option value={true}>{this.props.intl.formatMessage(messages.active)}</option>,
                <option value={false}>{this.props.intl.formatMessage(messages.inactive)}</option>
            ]
        },
    ]

    render() {
        const { handleSubmit, loading, submitting, id } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(this.handleSubmit)} >

                                    {
                                        this.fieldsArray().map(item => {
                                            if (item.field == 'image') return this.renderUploaderField({ label: item.label, name: item.name })
                                            if (item.field == 'dropdown') return this.renderDropdown({ label: item.label, name: item.name, options: item.options })
                                            return this.renderFieldComponent({ label: item.label, name: item.name })
                                        })
                                    }

                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={cx(s.displayInlineBlock, 'adminBtnSectionSpaceBottom')}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages[isNaN(id) ? "submitButton" : "update"])}
                                                    show={submitting || loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, 'rtlBtnLoader', 'adminBtnTextBrk')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/product"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL', 'adminBtnSectionSpaceBottom', 'adminBtnTextBrk')} >{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

ProductForm = reduxForm({
    form: 'ProductForm',
    validate
})(ProductForm);


const mapState = (state) => ({
})

const mapDispatch = {
}

export default compose(
    injectIntl,
    graphql(updateProductMutation),
    withStyles(s),
    connect(mapState, mapDispatch)
)(ProductForm);