import { addPayout } from '../../../../actions/shop/payout/addPayout';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

	if(!values.isEnable){
		toastr.warning('Error!', 'This feature is currently not available. Please contact admin for more information.');
		return;
	}

	dispatch(
		addPayout(
			values.email,
			values.address1,
			values.address2,
			values.city,
			values.state,
			values.country,
			values.zipcode,
			values.currency,
			values.firstname,
			values.lastname,
			values.accountNumber,
			values.routingNumber,
			values.ssn4Digits,
			values.businessType
		)
	);

};

export default submit;
