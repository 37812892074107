import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Home.css';
import getActiveCategories from './getActiveCategories.graphql'
import getSiteSettings from './getSiteSettings.graphql'
import getAllHomePageSettings from './getAllHomePageSettings.graphql'

//Components
import CitySection from '../../components/Home/CitySection/CitySection';
import { HomeProvider } from '../context/homeContext'
import HomeBanner from '../../components/Home/HomeBanner/HomeBanner';
import HomePartners from '../../components/Home/HomePartners/HomePartners';
import EatsMobileApp from '../../components/Home/EatsMobileApp/EatsMobileApp';
import DriverMobileApp from '../../components/Home/DriverMobileApp/DriverMobileApp';

class Home extends React.Component {

  render() {
    const { getCategories: { getActiveCategories }, getSiteSettings: { getSiteSettings, loading }, getAllHomePageSettings: { loading: homeLoading, getAllHomePageSettings } } = this.props
    let siteSettings = {};
    let homeSettings = {};
    if (!loading && !homeLoading) {
      getSiteSettings && getSiteSettings.map((item) => {
        siteSettings[item.name] = item.value
      });

      getAllHomePageSettings && getAllHomePageSettings.homePageData.map((item) => {
        homeSettings[item.name] = item.value
      });
    }

    return (
      <div className={s.overFlowHidden}>
        {
          !loading && !homeLoading && <div>
            <HomeProvider value={homeSettings}>
              <HomeBanner data={getAllHomePageSettings} />
              <HomePartners data={getAllHomePageSettings} />
              <EatsMobileApp data={getAllHomePageSettings} />
              <DriverMobileApp data={getAllHomePageSettings} />
              {/* {getActiveCategories && getActiveCategories.result && getActiveCategories.result.length > 0 &&
                <CitySection data={getActiveCategories} />
              } */}

            </HomeProvider>
          </div>
        }
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getActiveCategories, {
    name: 'getCategories',
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    }
  }),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: {
      ssr: true
    }
  }),
  graphql(getAllHomePageSettings, {
    name: 'getAllHomePageSettings',
    options: {
      ssr: true
    }
  }),
)(Home);
