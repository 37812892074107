import React, { Component } from 'react';
import ProductForm from '../../../components/SiteAdmin/Product/ProductForm'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getProduct from './getProduct.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditProduct.css';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

import getActiveStrains from './getActiveStrains.graphql';
import getActiveProducers from './getActiveProducers.graphql';
import getActiveCategories from './getActiveCategories.graphql';


export class EditProduct extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getProduct }, id, getActiveCategories, getActiveStrains, getActiveProducers } = this.props;

        let initialValues = {
            isActive: 'true',
            category: getActiveCategories?.getActiveCategories?.result[0]?.id,
            strain: getActiveStrains?.getActiveStrains?.results[0]?.id,
            producer: getActiveProducers?.getActiveProducers?.results[0]?.id,
        };

        if (!loading && getProduct?.result) {
            initialValues = {
                id: getProduct?.result?.id,
                producer: getProduct?.result?.producer,
                category: getProduct?.result?.category,
                productName: getProduct?.result?.productName,
                strain: getProduct?.result?.strain,
                thc: getProduct?.result?.thc,
                cbd: getProduct?.result?.cbd,
                cbg: getProduct?.result?.cbg,
                productRating: getProduct?.result?.productRating,
                numberOfReviews: getProduct?.result?.numberOfReviews,
                image: getProduct?.result?.image,
                isActive: getProduct?.result?.isActive.toString()
            }
        }
        if ((loading && id) || getActiveCategories?.loading || getActiveStrains?.loading || getActiveProducers?.loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages[id ? 'editProduct' : 'addProductHeading']} />
                        </div>
                        <div>
                            <ProductForm
                                id={id}
                                getActiveCategories={(getActiveCategories?.getActiveCategories?.result) || []}
                                getActiveProducers={(getActiveProducers?.getActiveProducers?.results) || []}
                                getActiveStrains={(getActiveStrains?.getActiveStrains?.results) || []}
                                initialValues={initialValues}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(
    withStyles(s),
    graphql(getProduct, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: true
        }),
        skip: props => Boolean(!props.id)
    }),
    graphql(getActiveCategories, {
        name: "getActiveCategories", options: {
            fetchPolicy: 'network-only',
        }
    }),
    graphql(getActiveStrains, {
        name: "getActiveStrains", options: {
            fetchPolicy: 'network-only',
        }
    }),
    graphql(getActiveProducers, {
        name: "getActiveProducers", options: {
            fetchPolicy: 'network-only',
        }
    })
)(EditProduct)
