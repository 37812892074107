import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import AddCategory from './AddCategory'
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.addCuisineHeading);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }

    // Admin restriction
    if (!restrictUrls('/siteadmin/category/add', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    return {
        title,
        component: (
            <AdminLayout>
                <AddCategory title={title} />
            </AdminLayout>
        )
    }
}

export default action;