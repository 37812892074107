import React, { Component } from 'react';
import ProducerForm from '../../../components/SiteAdmin/Producer/ProducerForm'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getProducer from './getProducer.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditProducer.css';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';


export class EditProducer extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getProducer }, id } = this.props;

        let initialValues = {
            isActive: 'true'
        };

        if (!loading && getProducer?.result) {
            initialValues = {
                id: getProducer?.result?.id,
                producerName: getProducer?.result?.producerName,
                producerStates: getProducer?.result?.producerStates,
                isActive: getProducer?.result?.isActive.toString()
            }
        }
        if (loading && id) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages[id ? 'editProducer' : 'addProducerHeading']} />
                        </div>
                        <div>
                            <ProducerForm initialValues={initialValues} id={id} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(withStyles(s), graphql(getProducer, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: true
    }),
    skip: props => Boolean(!props.id)
}))(EditProducer)
